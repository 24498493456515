import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.prototype.$jump = (url) => {
  if (!url) return;
  if (url.indexOf('http') == 0) {
    window.location.href = url;
    return;
  }
  router.push(url)
}

import request from "@/utils/request";
Vue.prototype.$request = request;

import BreadCrumbs from "@/components/breadCrumbs/index";
Vue.component('BreadCrumbs', BreadCrumbs);

Vue.config.ignoredElements = ['wx-open-launch-weapp']
// if (Auth.isWeixin()) {
//   Auth.wechat()
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');