<template>
  <div class="bread_crumbs fmix-align phone-hide">
    <div v-for="(item, index) in crumbs" :key="index">
      <span :class="{ link: item.url }" @click="$jump(item.url)">{{
        item.title
      }}</span>
      <span v-if="crumbs.length - 1 > index">/</span>
    </div>
  </div>
</template>

<script>
/**
 * 面包屑
 */
export default {
  name: "BreadCrumbs",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    crumbs() {
      if (this.list && this.list.length) return this.list;
      if (this.$route.path == "/") return [];
      let { breadCrumbs, title } = this.$route.meta;
      let dft = [{ title: "首页", url: "/" }];
      if (title) {
        dft.push({ title });
      }
      if (!breadCrumbs) return dft;
      if (breadCrumbs.hide) return [];
      if (breadCrumbs.data) return breadCrumbs.data;
      return dft;
    },
  },
};
</script>

<style scoped lang="scss">
.bread_crumbs {
  padding: 20px 10px;
  font-size: 14px;
  .link {
    color: $link;
    cursor: pointer;
  }
  span {
    margin-right: 10px;
  }
}
</style>