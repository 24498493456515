const Storage = window.localStorage;

export default {
    get(key) {
        if (!key) return null;
        let o = Storage.getItem(key);
        o = JSON.parse(o);
        if (!o || !o.value || !o.time) return null;
        let t = new Date().getTime();
        if (t > o.time) {
            this.remove(key);
            return null;
        }
        return o.value;
    },
    set(key, value, time) {
        if (!key) throw "key is not found";
        if (!time || isNaN(time)) {
            let t = new Date().getTime();
            time = 365 * 24 * 60 * 60 * 1000 + t;
        } else {
            let t = new Date().getTime();
            time = time * 1000 + t;
        }
        let data = JSON.stringify({ value, time });
        Storage.setItem(key, data);
    },
    remove(key) {
        if (!key) throw "key is not found";
        Storage.removeItem(key);
    },
    clear() {
        Storage.clear();
    },
    has(key) {
        if (!key) return false;
        const {
            keys
        } = this.info();
        return keys.includes(key);
    },
    info() {
        return Storage;
    }
}
