<template>
  <div>
    <Header :navs="navs" />
    <router-view />
    <Footer :links_site="links_site" :links="links" :about="about" :record_num="record_num" />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      navs: [],
      links_site: [],
      links: [],
      about: {},
      record_num: ''
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$request.get("index").then((res) => {
        let data = res.data;
        this.navs = data.home_menus;
        this.links_site = this.sliceArray(data.home_site_link, 4);
        this.links = this.sliceArray(data.home_friends_link, 4);
        this.about = data.about;
        this.record_num = data.record_num;
      });
    },
    sliceArray(data, size) {
      let arr = [];
      for (var i = 0; i < data.length; i += size) {
        arr.push(data.slice(i, i + size));
      }
      return arr;
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  font-size: 16px;
  font-family: "PingFang SC", Helvetica, "Microsoft YaHei", STHeiti, Tahoma;
  color: $dark-2;
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  display: block;
  width: 100%;
}
button,
input {
  border: 0;
  outline: none;
  background: none;
}

.flex-con {
  -webkit-box: 1;
  -moz-box: 1;
	-webkit-flex: 1;
  -ms-flexbox: 1;
	flex: 1;
}

.acea-row {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.acea-row.row-wrap {
	-webkit-box-lines: multiple;
	-moz-box-lines: multiple;
	-o-box-lines: multiple;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.acea-row.row-middle {
	-webkit-box-align: center;
	-moz-box-align: center;
	-o-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center
}

.acea-row.row-top {
	-webkit-box-align: start;
	-moz-box-align: start;
	-o-box-align: start;
	-ms-flex-align: start;
	-webkit-align-items: flex-start;
	align-items: flex-start
}

.acea-row.row-bottom {
	-webkit-box-align: end;
	-moz-box-align: end;
	-o-box-align: end;
	-ms-flex-align: end;
	-webkit-align-items: flex-end;
	align-items: flex-end
}

.acea-row.row-center {
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-o-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center
}

.acea-row.row-right {
	-webkit-box-pack: end;
	-moz-box-pack: end;
	-o-box-pack: end;
	-ms-flex-pack: end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end
}

.acea-row.row-left {
	-webkit-box-pack: start;
	-moz-box-pack: start;
	-o-box-pack: start;
	-ms-flex-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start
}

.acea-row.row-between {
	-webkit-box-pack: justify;
	-moz-box-pack: justify;
	-o-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between
}

.acea-row.row-around {
	justify-content: space-around;
	-webkit-justify-content: space-around
}

.acea-row.row-column-around {
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: space-around;
	-webkit-justify-content: space-around
}

.acea-row.row-column {
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-o-box-orient: vertical;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column
}

.acea-row.row-column-between {
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-o-box-orient: vertical;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-moz-box-pack: justify;
	-o-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between
}

.acea-row.row-center-wrapper {
	-webkit-box-align: center;
	-moz-box-align: center;
	-o-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-o-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center
}

.acea-row.row-between-wrapper {
	-webkit-box-align: center;
	-moz-box-align: center;
	-o-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-box-pack: justify;
	-moz-box-pack: justify;
	-o-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between
}


@font-face {
  font-family: "iconfont"; /* Project id 3427512 */
  src: url('//at.alicdn.com/t/c/font_3427512_b85jbv33q2w.woff2?t=1660890866237') format('woff2'),
       url('//at.alicdn.com/t/c/font_3427512_b85jbv33q2w.woff?t=1660890866237') format('woff'),
       url('//at.alicdn.com/t/c/font_3427512_b85jbv33q2w.ttf?t=1660890866237') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 1em;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
