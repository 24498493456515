import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from "@/utils/index";

Vue.use(VueRouter)

const importView = (path, ismul) => {
  ismul = ismul || 0;
  if (ismul == 1) {
    path += isMobile() ? '.mobile' : '.pc';
  }
  return import(`@/views/${path}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: '' },
    component: () => importView(`index/index`, 1)
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(`@/views/index/test.vue`)
  },
  {
    path: '/news/list',
    name: 'newsList',
    meta: { title: '新闻资讯' },
    component: () => import(`@/views/news/list.vue`)
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    meta: { title: '' },
    component: () => import(`@/views/news/detail.vue`)
  },
  {
    path: '/product',
    name: 'product',
    meta: { title: '产品与服务' },
    component: () => import(`@/views/product/index.vue`)
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: '走进润泽汇' },
    component: () => import(`@/views/about/index.vue`)
  },
  {
    path: '/about/pigeon',
    name: 'pigeon',
    meta: { title: '信鸽商城' },
    component: () => import(`@/views/about/pigeon.vue`)
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(`@/views/index/404.vue`)
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

//全局路由前置守卫
router.beforeEach((to, from, next) => {
  let { title } = to.meta;
  title = title ? (title + ' - ') : '';
  document.title = title + process.env.VUE_APP_NAME;
  window.scrollTo(0, 0);
  next();
});

export default router
