<template>
  <div>
    <div
      class="container pc-wrap dark scroll-mark phone-hide"
      style="padding-bottom: 20px"
    >
      <div class="pc-content">
        <div class="links flex">
          <div v-if="links_site.length" class="flex-con">
            <div class="links-title">站内链接</div>
            <div class="links-content flex">
              <div
                v-for="(item, index) in links_site"
                :key="index"
                class="flex-con"
              >
                <div
                  class="link-1"
                  v-for="(it, i) in item"
                  :key="i"
                  @click="$jump(it.link)"
                >
                  {{ it.name }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="links.length" class="flex-con">
            <div class="links-title">友情链接</div>
            <div class="links-content flex">
              <div v-for="(item, index) in links" :key="index" class="flex-con">
                <div class="link-1" v-for="(it, i) in item" :key="i">
                  <a :href="it.link" target="_blank">{{ it.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-con">
            <div class="links-title">联系我们</div>
            <div class="links-content">
              <div class="mb-20">
                {{ about.address }}
              </div>
              <div>电话：{{ about.tel }}</div>
              <div>邮箱: {{ about.email }}</div>
            </div>
          </div>
          <div v-if="about.about" class="flex-con">
            <div class="links-title">关于我们</div>
            <div class="links-content">
              {{ about.about }}
            </div>
          </div>
          <div class="flex-con">
            <div class="links-content">
              <div class="qrcode">
                <img src="@/assets/images/qrcode_01.jpg" alt="" />
              </div>
              <div class="qrcode">
                <img src="@/assets/images/qrcode_02.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pc-wrap copyright phone-hide">
      <div class="pc-content text-c">
        <div class="">
          <a href="https://beian.miit.gov.cn/">{{ record_num }}</a>
        </div>
      </div>
    </div>
    <div class="container pc-wrap phone-show scroll-mark dark">
      <div class="pc-content text-c">
        <div class="bold mb-20">联系我们</div>
        <div class="font-base">
          <div>地址：{{ about.address }}</div>
          <div class="mb-10">电话：{{ about.tel }}</div>
          <div>
            <a href="https://beian.miit.gov.cn/">{{ record_num }}</a>
          </div>
        </div>
        <div class="qrcodes acea-row row-between">
          <div class="qrcode-item">
            <div class="qrcode-img">
              <img
                src="https://img.runzehui.net/2022/08/19/dba80202208191521406693.png"
                alt=""
              />
            </div>
            <div>扫一扫<br />关注润泽汇公众号</div>
          </div>
          <div class="qrcode-item">
            <div class="qrcode-img">
              <img
                src="https://img.runzehui.net/2022/08/19/ea12b202208191521396992.png"
                alt=""
              />
            </div>
            <div>扫一扫<br />关注T+9品质生活空间</div>
          </div>
          <div class="qrcode-item">
            <div class="qrcode-img">
              <img
                src="https://img.runzehui.net/2022/08/19/7c6d820220819152139903.png"
                alt=""
              />
            </div>
            <div>扫一扫<br />免费领取更多福利</div>
          </div>
          <div class="qrcode-item">
            <div class="qrcode-img">
              <img
                src="https://img.runzehui.net/2022/08/19/995f5202208191521396073.png"
                alt=""
              />
            </div>
            <div>扫一扫<br />免费体验数字社交名片</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    links_site: {
      type: Array,
      default() {
        return [];
      },
    },
    links: {
      type: Array,
      default() {
        return [];
      },
    },
    about: {
      type: Object,
      default() {
        return {};
      },
    },
    record_num: String,
  },
};
</script>

<style scoped lang="scss">
.links {
  .links-title {
    font-size: 18px;
    font-weight: bold;
    color: $gray-c;
    margin-bottom: 20px;
  }

  .links-content {
    font-size: 12px;
    line-height: 2;

    .link-1 {
      margin: 10px 0;
      cursor: pointer;
      &::before {
        content: ">";
        display: inline;
        margin-right: 4px;
      }
    }
  }
}

.qrcode {
  float: right;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  padding: 10px;
  background: #fff;
}

.copyright {
  padding: 20px;
  font-size: 14px;
  color: #fff;
  border-top: 1px solid #2c2c2c;
  background: #1c1c1c;
  box-sizing: border-box;
}

.dark {
  background: #2c2c2c !important;
  color: #fff;
}

.qrcodes {
  margin-top: 20px;
  
  .qrcode-item {
    font-size: 12px;
    line-height: 20px;

    .qrcode-img {
      width: 72px;
      height: 72px;
      padding: 6px;
      background: #fff;
      border-radius: 6px;
      margin: auto;
      margin-bottom: 4px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>