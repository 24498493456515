export function isObject(obj) {
	return Object.prototype.toString.call(obj) === '[object Object]'
}
export function objectToStr(data, options) {
	var dft_opt = {
		start: '', // 起始符
		pre: '', // 前缀连接符
		even: '', // 键值连接符
		suf: '', // 后缀连接符
		end: '' // 结尾符
	};
	if (typeof options == 'string') {
		switch (options) {
			case 'style':
				options = {
					pre: ';',
					even: ':'
				};
				break;
			case 'url':
				options = {
					pre: '&',
					even: '='
				};
				break;
			case 'content':
				options = {
					pre: ',',
					even: '='
				};
				break;
		}
	}
	options = isObject(options) ? options : {};
	options = {
		...dft_opt,
		...options
	};
	var {
		start,
		pre,
		even,
		suf,
		end
	} = options;
	let str = start;
	for (var key in data) {
		str += pre + key + even + data[key] + suf;
	}
	return str + end;
}

export function formatDate(format, timestamp) {
	format = format || 'Y-m-d h:i:s';
	timestamp = timestamp || time(1);
	var a, jsdate = ((timestamp) ? new Date(timestamp * 1000) : new Date());
	var pad = function (n, c) {
		if ((n = n + "").length < c) {
			return new Array(++c - n.length).join("0") + n;
		} else {
			return n;
		}
	};
	var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	var txt_ordin = {
		1: "st",
		2: "nd",
		3: "rd",
		21: "st",
		22: "nd",
		23: "rd",
		31: "st"
	};
	var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September",
		"October", "November", "December"
	];
	var f = {
		d: function () {
			return pad(f.j(), 2);
		},
		D: function () {
			t = f.l();
			return t.substr(0, 3);
		},
		j: function () {
			return jsdate.getDate();
		},
		l: function () {
			return txt_weekdays[f.w()];
		},
		N: function () {
			return f.w() + 1;
		},
		S: function () {
			return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th';
		},
		w: function () {
			return jsdate.getDay();
		},
		z: function () {
			return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0;
		},
		W: function () {
			var a = f.z(),
				b = 364 + f.L() - a;
			var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
			if (b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b) {
				return 1;
			} else {
				if (a <= 2 && nd >= 4 && a >= (6 - nd)) {
					nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
					return formatDate("W", Math.round(nd2.getTime() / 1000));
				} else {
					return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
				}
			}
		},
		F: function () {
			return txt_months[f.n()];
		},
		m: function () {
			return pad(f.n(), 2);
		},
		M: function () {
			t = f.F();
			return t.substr(0, 3);
		},
		n: function () {
			return jsdate.getMonth() + 1;
		},
		t: function () {
			var n;
			if ((n = jsdate.getMonth() + 1) == 2) {
				return 28 + f.L();
			} else {
				if (n & 1 && n < 8 || !(n & 1) && n > 7) {
					return 31;
				} else {
					return 30;
				}
			}
		},
		L: function () {
			var y = f.Y();
			return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0;
		},
		Y: function () {
			return jsdate.getFullYear();
		},
		y: function () {
			return (jsdate.getFullYear() + "").slice(2);
		},
		a: function () {
			return jsdate.getHours() > 11 ? "pm" : "am";
		},
		A: function () {
			return f.a().toUpperCase();
		},
		B: function () {
			var off = (jsdate.getTimezoneOffset() + 60) * 60;
			var theSeconds = (jsdate.getHours() * 3600) +
				(jsdate.getMinutes() * 60) +
				jsdate.getSeconds() + off;
			var beat = Math.floor(theSeconds / 86.4);
			if (beat > 1000) beat -= 1000;
			if (beat < 0) beat += 1000;
			if ((String(beat)).length == 1) beat = "00" + beat;
			if ((String(beat)).length == 2) beat = "0" + beat;
			return beat;
		},
		g: function () {
			return jsdate.getHours() % 12 || 12;
		},
		G: function () {
			return jsdate.getHours();
		},
		h: function () {
			return pad(f.g(), 2);
		},
		H: function () {
			return pad(jsdate.getHours(), 2);
		},
		i: function () {
			return pad(jsdate.getMinutes(), 2);
		},
		s: function () {
			return pad(jsdate.getSeconds(), 2);
		},
		O: function () {
			var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4);
			if (jsdate.getTimezoneOffset() > 0) t = "-" + t;
			else t = "+" + t;
			return t;
		},
		P: function () {
			var O = f.O();
			return (O.substr(0, 3) + ":" + O.substr(3, 2));
		},
		c: function () {
			return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P();
		},
		U: function () {
			return Math.round(jsdate.getTime() / 1000);
		}
	};
	return format.replace(/[\\]?([a-zA-Z])/g, function (t, s) {
		let ret = '';
		if (t != s) {
			ret = s;
		} else if (f[s]) {
			ret = f[s]();
		} else {
			ret = s;
		}
		return ret;
	});
}

//是否为移动终端 排除pad|pod
export function isMobile() {
	var u = navigator.userAgent;
	return !!u.match(/(phone|iPhone|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	// return !!u.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}

//ios终端  
export function isIos() {
	var u = navigator.userAgent;
	return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

//android终端或者uc浏览器  
export function isAndroid() {
	var u = navigator.userAgent;
	return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
}

//浏览器内核
export function getBrowerKernel() {
	var u = navigator.userAgent;
	//IE内核  
	if (u.indexOf('Trident') > -1) return 'ie';
	//opera内核  
	else if (u.indexOf('Presto') > -1) return 'opera';
	//苹果、谷歌内核  
	else if (u.indexOf('AppleWebKit') > -1) return 'webkit';
	//火狐内核  
	else if (u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1) return 'gecko'
	return ''
}
