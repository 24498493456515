<template>
  <div>
    <div class="header pc-wrap" style="overflow: visible">
      <div class="pc-content fmix-align-sb">
        <img class="logo" src="@/assets/images/logo.png" @click="$jump('/')" />
        <div class="nav-icon">
          <img
            v-if="!popShow"
            src="@/assets/images/icon/list.png"
            @click="popShow = true"
          />
          <img
            v-else
            src="@/assets/images/icon/close.png"
            @click="popShow = false"
          />
        </div>
      </div>
    </div>
    <div class="pop-menu" :class="{ show: popShow }">
      <div class="pop-menu-list">
        <div
          v-for="(item, index) in navs"
          :key="index"
          class="pop-menu-item"
          @click="tapMenu(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="header_blank"></div>
  </div>
</template>

<script>
export default {
  name: "HeaderMobile",
  data() {
    return {
      popShow: false,
      navs: [
        {
          title: "产品服务",
          url: "/",
        },
        {
          title: "T+9品质生活空间",
          url: "/news/53",
        },
        {
          title: "润泽壹号酒业",
          url: "/news/52",
        },
        {
          title: "新闻资讯",
          url: "/news/list",
        },
        {
          title: "关于我们",
          url: "/about",
        },
      ],
    };
  },
  methods: {
    tapMenu(item) {
      if (this.popShow) {
        this.popShow = false;
      }
      this.$jump(item.url);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  color: $gray-d;
  background: #02010f;
  z-index: 99;

  .pc-content {
  }

  .logo {
    width: auto;
    height: 40px;
    cursor: pointer;
    margin: 0;
  }

  .nav-icon {
    width: 30px;
    position: absolute;
    right: 20px;
  }
}
.pop-menu {
  width: 100%;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;

  &.show {
    height: 100%;
  }

  .pop-menu-list {
    margin-top: 64px;
    padding: 10px 40px;
    color: $gray-d;
    background: #262626;
    border-top: 1px solid #3b3b3b;

    .pop-menu-item {
      padding: 15px 0;
      border-bottom: 1px solid #3b3b3b;
    }
  }
}

.header_blank {
  display: block;
  height: 64px;
}
</style>