import axios from "axios";
import md5 from "js-md5";
import storage from "./storage";

const { VUE_APP_API_URL } = process.env;

//小安修改，增加cookie跨域
axios.defaults.withCredentials = true;

const instance = axios.create({
    baseURL: VUE_APP_API_URL + '/api/',
    timeout: 20000,//小安修改，原来是5秒超时，改成10秒
});

const defaultOpt = {
    cache: 30
};

function baseRequest(options) {
    const { method, cache } = options;
    const cache_key = "cache_api_" + md5(options.url)
    if (method == 'get' && cache > 0) {
        const result = storage.get(cache_key);
        if (result) return Promise.resolve(result);
    }

    const headers = options.headers || {};
    //小安修改，开发模式下接口做标记
    if (process.env.NODE_ENV === "development") {
        headers["Development"] = "true";
    }
    options.headers = headers;
    return instance(options).then(res => {
        const data = res.data || {};

        if (res.status !== 200)
            return Promise.reject({ msg: "请求失败", res, data });

        if (data.status === 200) {
            if (method == 'get' && cache > 0) {
                storage.set(cache_key, data, cache);
            }
            return Promise.resolve(data, res);
        } else {
            return Promise.reject({ msg: res.data.msg, res, data });
        }
    });
}

const request = ["post", "put", "patch"].reduce((request, method) => {
    request[method] = (url, data = {}, options = {}) => {
        return baseRequest(
            Object.assign({ url, data, method }, defaultOpt, options)
        );
    };
    return request;
}, {});

["get", "delete", "head"].forEach(method => {
    request[method] = (url, params = {}, options = {}) => {
        return baseRequest(
            Object.assign({ url, params, method }, defaultOpt, options)
        );
    };
});

export default request;
