<template>
  <HeaderMobile v-if="isMobile" />
  <HeaderPc v-else />
</template>

<script>
import HeaderMobile from "./index.mobile.vue";
import HeaderPc from "./index.pc.vue";
import { isMobile } from "@/utils/index";

export default {
  name: "Header",
  components: {
    HeaderMobile,
    HeaderPc,
  },
  data() {
    return {
      isMobile: isMobile(),
    };
  },
};
</script>
