<template>
  <div>
    <div class="header pc-wrap" style="overflow: visible">
      <div class="pc-content fmix-align-sb">
        <img class="logo" src="@/assets/images/logo.png" @click="$jump('/')" />
        <div class="navs fmix-align" style="overflow: none">
          <a
            v-for="(item, index) in navs"
            :key="index"
            class="nav-item"
            :class="{ on: $route.path == item.url }"
            :href="item.url ? item.url : 'javascript:;'"
            :target="item.target ? '_blank' : ''"
          >
            {{ item.title }}
            <i v-if="item.children" class="iconfont">&#xe673;</i>
            <div
              v-if="item.children"
              class="popup-down"
              :class="item.children.type"
              :style="{ left: item.children.left || 0 }"
            >
              <div
                v-if="item.children.type == 'classNav'"
                class="popup-down-content acea-row"
              >
                <div
                  v-for="(list, indexl) in item.children.data"
                  :key="indexl"
                  class="popup-down-list"
                >
                  <div
                    v-if="list.title"
                    class="popup-down-title popup-down-item"
                  >
                    {{ list.title }}
                  </div>
                  <a
                    v-for="(it, indext) in list.children"
                    :key="indext"
                    class="popup-down-item"
                    :href="it.url ? it.url : 'javascript:;'"
                  >
                    {{ it.title }}
                  </a>
                </div>
              </div>
              <div v-if="item.children.type == 'img'" class="popup-down-imgs">
                <div
                  v-for="(img, indexl) in item.children.data"
                  :key="indexl"
                  class="popup-down-img"
                >
                  <img :src="img.img" alt="" />
                  <div v-if="img.title" class="popup-down-img-title">
                    {{ img.title }}
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div class="search">
            <div class="search-bar">
              <input type="text" />
              <i class="iconfont">&#xe679;</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header_blank"></div>
  </div>
</template>

<script>
import qrs from "@/assets/images/qrs.jpg";

export default {
  name: "HeaderPc",
  data() {
    return {
      popShow: false,
      navs: [
        {
          title: "首页",
          url: "/",
        },
        {
          title: "产品服务",
          url: "",
          children: {
            type: "classNav",
            left: "",
            data: [
              {
                title: "信鸽社交",
                children: [
                  {
                    title: "企业IP",
                    url: "/news/38",
                  },
                  {
                    title: "个人IP",
                    url: "/news/39",
                  },
                  {
                    title: "社团IP",
                    url: "/news/40",
                  },
                  {
                    title: "品牌IP",
                    url: "/news/41",
                  },
                  {
                    title: "产品IP",
                    url: "/news/42",
                  },
                  {
                    title: "活动IP",
                    url: "/news/77",
                  },
                ],
              },
              {
                title: "信鸽商城",
                children: [
                  {
                    title: "企业福采",
                    url: "/news/75",
                  },
                  {
                    title: "企业集采",
                    url: "/news/76",
                  },
                  {
                    title: "溯源商城",
                    url: "/news/21",
                  },
                  {
                    title: "润品商城",
                    url: "/news/20",
                  },
                ],
              },
              {
                title: "集成服务",
                children: [
                  {
                    title: "数字化转型",
                    url: "/news/46",
                  },
                  {
                    title: "SAAS工具赋能",
                    url: "/news/47",
                  },
                  {
                    title: "企业资源共享",
                    url: "/news/48",
                  },
                  {
                    title: "商品溯源",
                    url: "/news/49",
                  },
                  // {
                  //   title: "财税筹划",
                  //   url: "/news/50",
                  // },
                  {
                    title: "数字化营销",
                    url: "/news/51",
                  },
                  // {
                  //   title: "定制用酒",
                  //   url: "/news/52",
                  // },
                  // {
                  //   title: "品质生活空间",
                  //   url: "/news/53",
                  // },
                  // {
                  //   title: "平台积分互链",
                  //   url: "/news/54",
                  // },
                  {
                    title: "对外开发系统",
                    url: "/news/55",
                  },
                ],
              },

            ],
          },
        },
        {
          title: "T+9品质生活空间",
          url: "/news/53",
        },
        {
          title: "润泽壹号酒业",
          url: "/news/52",
        },
		{
		  title: "信鸽商城",
		  url: "/news/88",
		},
        {
          title: "关于我们",
          url: "/about",
        },
        {
          title: "微信端",
          url: "",
          children: {
            type: "img",
            left: "-50px",
            data: [
              {
                img: qrs,
              },
            ],
          },
        },
      ],
    };
  },
  methods: {
    tapMenu(item) {
      if (this.popShow) {
        this.popShow = false;
      }
      this.$jump(item.url);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  color: $gray-d;
  background: #02010f;
  z-index: 99;

  .pc-content {
  }

  .logo {
    width: auto;
    height: 40px;
    cursor: pointer;
  }

  .navs {
    .nav-item {
      height: 64px;
      font-size: 16px;
      line-height: 64px;
      padding: 0 20px;
      cursor: pointer;
      position: relative;

      &:hover,
      &.on {
        color: #fff;
        background: #100a64;
      }

      &:hover {
        .popup-down {
          display: block;
        }
      }

      .iconfont {
        font-size: 0.8em;
      }
    }
  }

  .search {
    margin-left: 20px;
    .search-bar {
      background: #3e3e3e;
      padding: 6px;
      border-radius: 20px;
      input {
        width: 100px;
        color: #fff;
      }
    }
  }

  .popup-down {
    display: none;
    position: absolute;
    top: 64px;
    left: 0;
    font-size: 14px;
    line-height: 1.4;
    color: #fff;
    padding: 20px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    z-index: 9999;

    &.img {
      background: #fff;
      padding: 5px;
    }

    .popup-down-content {
      // border: 2px solid #100964;
      padding: 20px 0;

      .popup-down-list {
        padding: 0 40px;
        // border-left: 1px solid #100964;
      }

      .popup-down-list:first-of-type {
        padding: 0 40px;
        border-left: 0;
      }

      .popup-down-title {
        font-size: 16px;
        padding: 0;
        margin-bottom: 10px;
      }

      .popup-down-item {
        display: block;
        white-space: nowrap;
        padding: 10px 0;
        letter-spacing: 2px;
      }
    }

    .popup-down-imgs {
      .popup-down-img {
        width: 300px;
        margin-bottom: 20px;
        color: #333;
        font-size: 12px;
        text-align: center;
        img {
          display: block;
          width: 100%;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .popup-down-img-title {
        margin-top: 10px;
      }
    }
  }
}
.header_blank {
  display: block;
  height: 64px;
}
</style>